:root {
    --radius: 2px;
    
    --accentFg: whitesmoke;
    --baseBg: rgb(39, 39, 39);
    --accentBg: rgb(115, 115, 115);
    --baseFg: whitesmoke;

    --fontWeight:bolder;
    --fontSize: 100%;
    --fontFamily: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

    --iconWidth: 30%;
    --iconHeight: 70%;

    --line-height: 1;
    --text-shadow: none;

  }
  
  .PlayerPickerSelectStyle_themePink__3j9l8 {
    --radius: 0;
    --baseFg: #c70062;
    --baseBg: #ffe3f1;
    --accentFg: #c70062;
    --accentBg: #ffaad4;
  }
  
  .PlayerPickerSelectStyle_theme-construction__1xAy1 {
    --radius: 0;
    --baseFg: white;
    --baseBg: black;
    --accentFg: black;
    --accentBg: orange;
  }

  .PlayerPickerSelectStyle_themePlayerPicker__2P5V5 {
    --radius: 0.1rem;
    --baseFg: rgb(235, 113, 52);
    --baseBg: rgb(39, 39, 39);
    --accentFg: rgb(235, 113, 52);
    --accentBg: rgb(26, 26, 26);
    --fontSize: 180%;
    --iconWidth: 10%;
    --iconHeight: 60%;
    --line-height: 1.5;
    --text-shadow: 0.1rem 0.1rem 0.1rem #000000;
  }
  
  select {
    width: 100%;
    height: 100%;
    font-weight:bolder;
    font-weight:var(--fontWeight);
    font-size: 100%;
    font-size: var(--fontSize);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-family: var(--fontFamily);
    text-shadow: none;
    text-shadow: var(--text-shadow);
    -webkit-appearance: none;
    appearance: none;
    color: whitesmoke;
    color: var(--baseFg);
    border: 1px solid whitesmoke;
    border: 1px solid var(--baseFg);
    line-height: 1;
    line-height: var(--line-height);
    outline: 0;
    padding: 0.05em 2em 0.05em 0.2em;
    border-radius: 2px;
    border-radius: var(--radius);
    background-color: rgb(39, 39, 39);
    background-color: var(--baseBg);
    background-image: linear-gradient(whitesmoke, whitesmoke),
      linear-gradient(-135deg, transparent 50%, rgb(115, 115, 115) 50%),
      linear-gradient(-225deg, transparent 50%, rgb(115, 115, 115) 50%),
      linear-gradient(rgb(115, 115, 115) 42%, whitesmoke 42%);
    background-image: linear-gradient(var(--baseFg), var(--baseFg)),
      linear-gradient(-135deg, transparent 50%, var(--accentBg) 50%),
      linear-gradient(-225deg, transparent 50%, var(--accentBg) 50%),
      linear-gradient(var(--accentBg) 42%, var(--accentFg) 42%);
    background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
    background-size: 0.1rem 100%, 30% 70%, 30% 70%, 30% 100%;
    background-size: 0.1rem 100%, var(--iconWidth) var(--iconHeight), var(--iconWidth) var(--iconHeight), var(--iconWidth) 100%;
    background-position: right 30% center, right bottom, right bottom, right bottom;
    background-position: right var(--iconWidth) center, right bottom, right bottom, right bottom;   
  }
  
  select:hover {
    background-image: linear-gradient(whitesmoke, whitesmoke),
      linear-gradient(-135deg, transparent 50%, whitesmoke 50%),
      linear-gradient(-225deg, transparent 50%, whitesmoke 50%),
      linear-gradient(whitesmoke 42%, rgb(115, 115, 115) 42%);
    background-image: linear-gradient(var(--accentFg), var(--accentFg)),
      linear-gradient(-135deg, transparent 50%, var(--accentFg) 50%),
      linear-gradient(-225deg, transparent 50%, var(--accentFg) 50%),
      linear-gradient(var(--accentFg) 42%, var(--accentBg) 42%);
  }
  
  select:active {
    background-image: linear-gradient(whitesmoke, whitesmoke),
      linear-gradient(-135deg, transparent 50%, whitesmoke 50%),
      linear-gradient(-225deg, transparent 50%, whitesmoke 50%),
      linear-gradient(whitesmoke 42%, rgb(115, 115, 115) 42%);
    background-image: linear-gradient(var(--accentFg), var(--accentFg)),
      linear-gradient(-135deg, transparent 50%, var(--accentFg) 50%),
      linear-gradient(-225deg, transparent 50%, var(--accentFg) 50%),
      linear-gradient(var(--accentFg) 42%, var(--accentBg) 42%);
    border-color: whitesmoke;
    border-color: var(--accentFg);
  }

  @media only screen and (max-width: 1080px) {
    .PlayerPickerSelectStyle_themePlayerPicker__2P5V5 {
      --fontSize: 150%;
    }
  }
.StatComponentStyle_componentSize__3D6HD{
    display:block;
    width: 100%;
    height: 100%;
}

.StatComponentStyle_background__1ffFI {
    display: block;
    background: rgb(85,85,85);
    background: radial-gradient(circle, rgba(85,85,85,1) 0%, rgba(41,41,41,1) 57%, rgba(0,0,0,1) 100%);
    border-radius: 0.4rem;
    box-shadow: .1rem .1rem .2rem black;
    width: 100%;
    height: 100%;
    text-align: center;
}

.StatComponentStyle_contentBox__5logC {
    display: grid;
    grid-template-rows: 20% 60% 20%;
    height: 100%;
    
}

.StatComponentStyle_contentBoxNumbers__1-h1p {
    display: grid;
    grid-template-rows: 20% 50% 25%;
    height: 100%;
    
}

.StatComponentStyle_fourColumns__1A0Xc{
    display: grid;
    grid-template-columns: 15% 30% 30% 15%;
    grid-column-gap: 3%;
    -webkit-column-gap: 3%;
            column-gap: 3%;
    position: relative;
    margin:auto;
    bottom: 0;
    text-align: center;
    justify-content: center;
    height: 100%;
}

.StatComponentStyle_twoColumns__1i0pw{
    display: grid;
    grid-template-columns: 40% 40%;
    grid-column-gap: 3%;
    -webkit-column-gap: 3%;
            column-gap: 3%;
    position: relative;
    margin-top: 2.5%;
    bottom: 0;
    text-align: center;
    justify-content: center;
    height: 100%;
    overflow: hidden;
}

.StatComponentStyle_numberStatThreeRows__3qguK{
    display: grid;
    grid-template-rows: 55% 25% 20%;
    text-align: center;
    margin-bottom: 4%;
    width: 100%;
}

.StatComponentStyle_numberStatTwoRows__JGQED{
    display: grid;
    grid-template-rows: 55% 30% ;
    text-align: center;
    margin: auto;
    width: 100%;
}

.StatComponentStyle_numberStatText__1gi_Q{
    font-size: 350%;
    font-weight: bolder; 
    margin: auto;
}

.StatComponentStyle_flex100__2N5bD{
    display: flex;
    height: 100%;
    margin-bottom: 0%;
    bottom: 0%;
}
.StatComponentStyle_blockMid100__3tvsU{
    display: flex;
    height: 100%;
    width: 100%;
    text-align: center;
    margin: auto;

}

.StatComponentStyle_block100__xmtfg{
    display: grid;
    /* grid-template-rows: 0% 16.66% 16.66% 16.66% 16.66% 16.66% 16.66% 0%; */
    grid-template-rows: 0% 20% 20% 20% 20% 20% 0% 0%;
}

.StatComponentStyle_statText__3o8ex{
    font-size: larger;
    color: black;
}

.StatComponentStyle_playerName__1cPGg{
    font-size:large;
    color: white;
    text-align: center;
    margin: auto;
}

.StatComponentStyle_scaleText__AFhy-{
    font-size: small;
    color: white;
    margin: auto;
    margin-top: -20%;
}

h2{
    color: beige;
}

.StatComponentStyle_header__3y-39{
    margin-top: 5px;
}

@media only screen and (max-width: 1240px) {
    .StatComponentStyle_numberStatText__1gi_Q{
        font-size: 300%;
    }
}


@media only screen and (max-width: 1000px), screen and (max-height: 1000px) {
    .StatComponentStyle_componentSize__3D6HD{
        display: inline;
        max-width: 410px;
    }

    .StatComponentStyle_numberStatText__1gi_Q{
        font-size: 350%;
    }
}

@media only screen and (max-width: 420px) {
    
}

@media only screen and (max-width: 330px) {
    .StatComponentStyle_numberStatText__1gi_Q{
        font-size: 250%;
    }
}
.ScaleComponentStyle_scaleText__2y1-m{
    font-size: small;
    color: white;
    margin: auto;
    margin-top: -0.5rem;
    margin-right: 0%;
    width: 100%;
}

.ScaleComponentStyle_line__2Q-jY{
    position: absolute;
    width: 68%;
    margin-left: 15%;
    margin-top: -0.5rem;
}

.ScaleComponentStyle_verticalLine__24U5Q{
    position: absolute;
    border-left: 1px solid white;
    height: 98%;
    margin-left: 10%;
    margin-top: .5%;
}
.BarStatBarStyle_statText__cZCqU{
    font-size: larger;
    color: black;
    text-align:center;
    margin:auto;
}

.BarStatBarStyle_statTextOver__VVPiA{
    font-size: larger;
    color: rgb(255, 255, 255);
    text-align:center;
    margin-top:-1em;
    text-shadow: 0.05rem 0.05rem 0.1rem #000000;
}

@media only screen and (max-width: 1240px) {

    .BarStatBarStyle_statText__cZCqU{
        font-size: small;
    }
    
    .BarStatBarStyle_statTextOver__VVPiA{
        font-size: small;
    }
}

@media only screen and (max-width: 1080px) {
    .BarStatBarStyle_statText__cZCqU{
        font-size: large;
    }
    
    .BarStatBarStyle_statTextOver__VVPiA{
        font-size: large;
    }
}

@media only screen and (max-width: 420px) {
    .BarStatBarStyle_statText__cZCqU{
        font-size: smaller;
    }
    
    .BarStatBarStyle_statTextOver__VVPiA{
        font-size: smaller;
    }
}
:root{
    --main-bg-color: rgb(50, 50, 50);
    --main-content-bg-color: rgb(39, 39, 39);
    --main-section-bg-color: rgb(115, 115, 115);
    --main-section-border-color: rgb(77, 75, 75);

    --default-content-column-width: 100%;
    --default-content-column-height: 94%;
    --default-column-gap: 1%;
    --default-row-gap: 1%;
    --default-margin: 1%;
    --default-padding: 1%;

    --content-margin: 2.5%;

    --dev-grid-bg-color: none;
}

.LayoutStyle_background__2PA5r{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    height: auto;
    padding: 0;
    margin: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(50, 50, 50);
    background: var(--main-bg-color);
}

.LayoutStyle_homePageContent__2JyY9{
    display: flex;
    flex-direction: column;
    margin: 20px 2.5% 0 2.5%;
    margin: 20px var(--content-margin) 0 var(--content-margin);
    width: 95%;
    max-width: 1720px;
    min-width: 280px;
    overflow:auto;
    padding: 1%;
    padding: var(--default-padding);
    height: 95%;
    background: rgb(39, 39, 39);
    background: var(--main-content-bg-color);
    box-shadow: .05rem .05rem .2rem rgba(106, 100, 100, 0.484);
}

.LayoutStyle_homePageGridRows__NHvbt{
    display: grid;
    grid-template-rows: 12% 30% 57%;
    grid-row-gap: 1%;
    row-gap: 1%;
    grid-row-gap: var(--default-row-gap);
    row-gap: var(--default-row-gap);
    height: 100%;
    width: 100%;
    margin: auto;
}

.LayoutStyle_sectionBackground__2frVI{
    display: flex;
    width: 100%;
    height: 100%;
    background: rgb(115, 115, 115);
    background: var(--main-section-bg-color);
    border: 0 outset;
    box-sizing: border-box;
    box-shadow: inset 0 0 0 0.3rem rgb(77, 75, 75);
    box-shadow: inset 0 0 0 0.3rem var(--main-section-border-color);
    justify-content: center;
    align-items: center;
}

.LayoutStyle_sectionBackgroundRadar__tOZys{
    display: flex;
    width: 100%;
    height: 100%;
    background: rgb(115, 115, 115);
    background: var(--main-section-bg-color);
    border: 0 outset;
    box-sizing: border-box;
    box-shadow: inset 0 0 0 0.3rem rgb(77, 75, 75);
    box-shadow: inset 0 0 0 0.3rem var(--main-section-border-color);
    justify-content: center;
    align-items: center;
}

.LayoutStyle_statPickers__2UeFZ{
    display: block;
    text-align: end;
}

.LayoutStyle_homePageGridRowHeader__18LBE{
    display: grid;
    grid-template-rows: 53.5% 44.5%;
    grid-row-gap: 1%;
    row-gap: 1%;
    grid-row-gap: var(--default-column-gap);
    row-gap: var(--default-column-gap);
}

.LayoutStyle_headerDisplay__2AyXD{
    display: flex;
}

.LayoutStyle_homePageGridColumnStrokes__3ctnP{
    display: grid;
    grid-template-columns: 38% 14% 14% 14% 14%;
    grid-column-gap: 1%;
    -webkit-column-gap: 1%;
            column-gap: 1%;
    grid-column-gap: var(--default-column-gap);
    -webkit-column-gap: var(--default-column-gap);
            column-gap: var(--default-column-gap);
    width: 100%;
    width: var(--default-content-column-width);
    height: 90%;
    justify-content: center;
}

.LayoutStyle_homePageGridColumnStats__2T3_O{
    display: grid;
    grid-template-columns: 39.5% 59.5%;
    grid-column-gap: 1%;
    -webkit-column-gap: 1%;
            column-gap: 1%;
    grid-column-gap: var(--default-column-gap);
    -webkit-column-gap: var(--default-column-gap);
            column-gap: var(--default-column-gap);
    width: 100%;
    width: var(--default-content-column-width);
    height: 100%;
    margin: auto;
    justify-content: center;
    align-items: center;
}

.LayoutStyle_header__1Qupu{
    font-size: 300%;
    margin: 0;
    width: 100%;
}

.LayoutStyle_pictureGrid__ZE_YK{
    display: block;
    height: 100%;
    width: 100%;
    margin: auto;
    background: none;
    background: var(--dev-grid-bg-color);
}

.LayoutStyle_nameGrid__2pzzt{
    display: flex;
    height: 100%;
    width: 60%;
    text-align: start;
    align-items: center;
    margin-top: 5px;
    background: none;
    background: var(--dev-grid-bg-color);
}

.LayoutStyle_positionDisplay__fUlmY{
    display: grid;
    grid-template-columns: 60% 35%;
    grid-column-gap: 1%;
    -webkit-column-gap: 1%;
            column-gap: 1%;
    grid-column-gap: var(--default-column-gap);
    -webkit-column-gap: var(--default-column-gap);
            column-gap: var(--default-column-gap);
    width: 100px;
    margin: auto;
    margin-left: 3%;
    margin-top: -5px;
}

.LayoutStyle_positionDisplayNumber__1dxLl{
    font-size: 300%;
    margin: auto;
}

.LayoutStyle_infoGrid__3X2HB{
    display: block;
    margin-right: 0;
    margin-left: auto;
    width: 80%;
    background: none;
    background: var(--dev-grid-bg-color);
}

.LayoutStyle_strokesBox__1VCev{
    display: block;
    height: 100%;
    width: 100%;
    margin: auto;
    background: none;
    background: var(--dev-grid-bg-color);
}

.LayoutStyle_strokeStatBox__3YUOq{
    display: block;
    min-height: 100%;
    height: 100%;
    width: 100%;
    margin: auto;
    background: none;
    background: var(--dev-grid-bg-color);
}

.LayoutStyle_playerMapBox__11XAR{
    display: block;
    width: 96%;
    height: 94%;
    height: var(--default-content-column-height);
    margin: auto;
    background: none;
    background: var(--dev-grid-bg-color);
}

.LayoutStyle_statsBox__1Y_MH{
    display: grid;
    grid-template-rows: 49.5% 49.5%;
    grid-row-gap: 1%;
    row-gap: 1%;
    grid-row-gap: var(--default-row-gap);
    row-gap: var(--default-row-gap);
    width: 97.5%;
    height: 94%;
    height: var(--default-content-column-height);
    background: none;
    background: var(--dev-grid-bg-color);
}

.LayoutStyle_statsBoxRow__-uYWj{
    display: grid;
    grid-template-columns: 32.75% 32.5% 32.75% ;
    grid-column-gap: 1%;
    -webkit-column-gap: 1%;
            column-gap: 1%;
    grid-column-gap: var(--default-column-gap);
    -webkit-column-gap: var(--default-column-gap);
            column-gap: var(--default-column-gap);
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background: none;
    background: var(--dev-grid-bg-color);
}

p{
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: rgb(252, 239, 239)
}

h1{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: rgb(252, 239, 239);
    text-shadow: 0.2rem 0.2rem 0.2rem #000000;
}

h2{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    text-shadow: 0.1rem 0.1rem 0.1rem #000000;
}

footer{
    display: flex;
    max-width: 1720px;
    height: 30px;
    margin-top: 0;
    margin-bottom: 5px;
    grid-gap: 20px;
    gap: 20px;
    justify-content: center;
    align-items: center;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: rgb(252, 239, 239)
}

@media only screen and (max-width: 1240px) {

    h2{
        font-size: large;
    }
}

@media only screen and (max-width: 1080px), screen and (max-height: 900px) {
    .LayoutStyle_homePageContent__2JyY9{
        display: flex;
        margin-top: 10px;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .LayoutStyle_homePageGridRows__NHvbt{
        display: inline;
        margin: auto;
    }

    .LayoutStyle_sectionBackground__2frVI{
        display: flex;
        height:900px;
    }
    .LayoutStyle_sectionBackgroundRadar__tOZys{
        display: flex;
        height: auto;
    }

    .LayoutStyle_homePageGridRowHeader__18LBE{
        display: grid;
        min-height: 200px;
        justify-content: center;
        align-items: center;
        grid-template-rows: 59.5% 36.5%;
        grid-row-gap: 1%;
        row-gap: 1%;
        grid-row-gap: var(--default-column-gap);
        row-gap: var(--default-column-gap);
    }

    .LayoutStyle_headerDisplay__2AyXD{
        display: inline-block;
        margin: auto;
    }
    
    .LayoutStyle_homePageGridColumnStrokes__3ctnP{
        display: inline-block;
        justify-content: center;
        align-items: center;
        width: 96%;
        height: 98%;
    }

    .LayoutStyle_homePageGridColumnStats__2T3_O{
        display: inline-block;
        width: 100%;
        width: var(--default-content-column-width);
        height: 100%;
        margin: auto;
    }

    .LayoutStyle_pictureGrid__ZE_YK{
        display: block;
        height: 100%;
        width: 100%;
        margin: auto;
        background: none;
        background: var(--dev-grid-bg-color);
    }
    
    .LayoutStyle_nameGrid__2pzzt{
        width: 550px;
        margin: auto;
        margin-left: 230px;
    }

    .LayoutStyle_positionDisplay__fUlmY{
        margin-left: 2%;
        margin-top: 0;
    }

    .LayoutStyle_statPickers__2UeFZ{
        display: block;
        text-align: start;
    }
    
    .LayoutStyle_infoGrid__3X2HB{
        display: inline-block;
        width: 100%;
        margin: auto;
        background: none;
        background: var(--dev-grid-bg-color);
    }
    
    .LayoutStyle_strokesBox__1VCev{
        display: inline-block;
        max-width: 98%;
        height: 300px;
        padding: 1%;
        padding: var(--default-padding);
        margin: auto;
        background: none;
        background: var(--dev-grid-bg-color);
    }
    
    .LayoutStyle_strokeStatBox__3YUOq{
        display: inline-block;
        max-width: 48%;
        height: 250px;
        min-height: 200px;
        padding: 1%;
        padding: var(--default-padding);
        margin: auto;
        background: none;
        background: var(--dev-grid-bg-color);
    }
    
    .LayoutStyle_playerMapBox__11XAR{
        display: inline;
        padding: 3%;
        margin: auto;
        background: none;
        background: var(--dev-grid-bg-color);
    }
    
    .LayoutStyle_statsBox__1Y_MH{
        display: inline;
        justify-items: center;
        width: 96%;
        height: 94%;
        height: var(--default-content-column-height);
        background: none;
        background: var(--dev-grid-bg-color);
    }
    
    .LayoutStyle_statsBoxRow__-uYWj{
        display: inline;
        justify-content: center;
        align-items: center;
        width: 100%;
        background: none;
        background: var(--dev-grid-bg-color);
    }

    p{
        font-size:medium;
    }
    
    h1{
        font-size:medium;
    }
    
    h2{
        font-size:larger;
    }

    footer{
        margin-top: 0%;
    }
}

@media only screen and (max-width: 540px) {

    .LayoutStyle_positionDisplay__fUlmY{
        display: none;
    }

    footer{
        flex-direction: column;
        grid-gap: 2px;
        gap: 2px;

        
    }

    footer p{
        margin: auto;
    }

    p{
        font-size:smaller;
    }
}

@media only screen and (max-width: 420px) {

    .LayoutStyle_nameGrid__2pzzt{
        width: 100%;
        margin: auto;
    }

    p{
        font-size:small;
    }
    
    h1{
        font-size:medium;
    }
    
    h2{
        font-size:large;
    }
}

@media only screen and (max-width: 390px) {
    p{
        font-size:x-small;
    }
}

@media only screen and (max-width: 360px) {
    .LayoutStyle_header__1Qupu{
        font-size: 250%;
    }

    .LayoutStyle_homePageGridRowHeader__18LBE{
        display: grid;
        min-height: 200px;
        grid-template-rows: 60% 30%;
        grid-row-gap: 1%;
        row-gap: 1%;
        grid-row-gap: var(--default-column-gap);
        row-gap: var(--default-column-gap);
    }
}

@media only screen and (max-width: 327px) {

    .LayoutStyle_homePageGridRowHeader__18LBE{
        min-height: 240px;
        grid-template-rows: 70% 20%;
    }
}
.StrokesComponentStyle_componentSize__2wLoD{
    display: block;
    width: 100%;
    height: 100%;
}

.StrokesComponentStyle_background__24F-h {
    display: block;
    background: rgb(85,85,85);
    background: radial-gradient(circle, rgba(85,85,85,1) 0%, rgba(41,41,41,1) 57%, rgba(0,0,0,1) 100%);
    border-radius: 0.4rem;
    box-shadow: .1rem .1rem .2rem black;
    width: 100%;
    height: 100%;
    text-align: center;
}

.StrokesComponentStyle_contentBox__3_Owj {
    display: grid;
    grid-template-rows: 20% 60% 20%;
    height: 100%;
    width: 100%;
    margin: auto;
    
}

.StrokesComponentStyle_contentBoxNumbers__2ZzrV {
    display: grid;
    grid-template-rows: 20% 55% 25%;
    height: 100%;
    
}

.StrokesComponentStyle_threeColumns__3pAsO{
    display: grid;
    grid-template-columns: 30% 30% 30%;
    grid-column-gap: 0%;
    -webkit-column-gap: 0%;
            column-gap: 0%;
    position: relative;
    margin-left: 10%;
    bottom: 0;
    text-align: center;
    justify-content: center;
    height: 100%;
}

.StrokesComponentStyle_twoColumns__36Q3x{
    display: grid;
    grid-template-columns: 40% 40%;
    grid-column-gap: 3%;
    -webkit-column-gap: 3%;
            column-gap: 3%;
    position: relative;
    margin-top: 2.5%;
    bottom: 0;
    text-align: center;
    justify-content: center;
    height: 100%;
}

.StrokesComponentStyle_twoRows__z3k4F{
    display: grid;
    grid-template-rows: 0% 50% 50%;
    position: relative;
    margin-top: 0%;
    text-align: center;
    width: 100%;
    height: 100%;
}

.StrokesComponentStyle_gridTwoColumns__ceTMJ{
    display: grid;
    grid-template-columns: 15% 80%;
    margin-left: 0%;
    margin-right: 0%;
    width: 100%;
}

.StrokesComponentStyle_flex100__HK2_S{
    display: flex;
    height: 100%;
}
.StrokesComponentStyle_blockMid100__2vz7I{
    display: flex;
    height: 100%;
    width: 100%;
    text-align: center;
    margin: auto;

}

.StrokesComponentStyle_block100__29gB2{
    display: grid;
    /* grid-template-rows: 0% 16.66% 16.66% 16.66% 16.66% 16.66% 16.66% 0%; */
    grid-template-rows: 0% 20% 20% 20% 20% 20% 20% 0%;
}

.StrokesComponentStyle_block100Vertical__2_5ne{
    display: grid;
    /* grid-template-rows: 0% 16.66% 16.66% 16.66% 16.66% 16.66% 16.66% 0%; */
    grid-template-columns: 0% 20% 20% 20% 20% 20% 20% 0%;
    margin-left: -10%;
    width: 100%;
    height: 100%;
}

.StrokesComponentStyle_gridRows__1Sfgq{
    display: grid;
    grid-template-rows: 50% 50%;
    margin-top: 0%;
}

.StrokesComponentStyle_barsPlacement__3qSjt{
    display: grid;
    grid-template-rows: 45% 45%;
    margin-top: 0%;
}

.StrokesComponentStyle_statText__3sgRb{
    font-size: larger;
    color: black;
}

.StrokesComponentStyle_playerName__2e3ri{
    font-size:large;
    color: white;
    text-align: center;
    margin: auto;
    overflow: 'hidden',
}

.StrokesComponentStyle_scaleText__3m-ti{
    font-size: small;
    color: white;
    margin: auto;
    margin-top: -20%;
}

.StrokesComponentStyle_header__3iRtb{
    margin-top: 5px;
}

h2{
    color: beige;
}

@media only screen and (max-width: 1000px) {
    .StrokesComponentStyle_componentSize__2wLoD{
        display: block;
        max-height: 300px;
    }
}

@media only screen and (max-width: 420px) {
    .StrokesComponentStyle_playerName__2e3ri{
        font-size:small;
    }
}
