.componentSize{
    display:block;
    width: 100%;
    height: 100%;
}

.background {
    display: block;
    background: rgb(85,85,85);
    background: radial-gradient(circle, rgba(85,85,85,1) 0%, rgba(41,41,41,1) 57%, rgba(0,0,0,1) 100%);
    border-radius: 0.4rem;
    box-shadow: .1rem .1rem .2rem black;
    width: 100%;
    height: 100%;
    text-align: center;
}

.contentBox {
    display: grid;
    grid-template-rows: 20% 60% 20%;
    height: 100%;
    
}

.contentBoxNumbers {
    display: grid;
    grid-template-rows: 20% 50% 25%;
    height: 100%;
    
}

.fourColumns{
    display: grid;
    grid-template-columns: 15% 30% 30% 15%;
    column-gap: 3%;
    position: relative;
    margin:auto;
    bottom: 0;
    text-align: center;
    justify-content: center;
    height: 100%;
}

.twoColumns{
    display: grid;
    grid-template-columns: 40% 40%;
    column-gap: 3%;
    position: relative;
    margin-top: 2.5%;
    bottom: 0;
    text-align: center;
    justify-content: center;
    height: 100%;
    overflow: hidden;
}

.numberStatThreeRows{
    display: grid;
    grid-template-rows: 55% 25% 20%;
    text-align: center;
    margin-bottom: 4%;
    width: 100%;
}

.numberStatTwoRows{
    display: grid;
    grid-template-rows: 55% 30% ;
    text-align: center;
    margin: auto;
    width: 100%;
}

.numberStatText{
    font-size: 350%;
    font-weight: bolder; 
    margin: auto;
}

.flex100{
    display: flex;
    height: 100%;
    margin-bottom: 0%;
    bottom: 0%;
}
.blockMid100{
    display: flex;
    height: 100%;
    width: 100%;
    text-align: center;
    margin: auto;

}

.block100{
    display: grid;
    /* grid-template-rows: 0% 16.66% 16.66% 16.66% 16.66% 16.66% 16.66% 0%; */
    grid-template-rows: 0% 20% 20% 20% 20% 20% 0% 0%;
}

.statText{
    font-size: larger;
    color: black;
}

.playerName{
    font-size:large;
    color: white;
    text-align: center;
    margin: auto;
}

.scaleText{
    font-size: small;
    color: white;
    margin: auto;
    margin-top: -20%;
}

h2{
    color: beige;
}

.header{
    margin-top: 5px;
}

@media only screen and (max-width: 1240px) {
    .numberStatText{
        font-size: 300%;
    }
}


@media only screen and (max-width: 1000px), screen and (max-height: 1000px) {
    .componentSize{
        display: inline;
        max-width: 410px;
    }

    .numberStatText{
        font-size: 350%;
    }
}

@media only screen and (max-width: 420px) {
    
}

@media only screen and (max-width: 330px) {
    .numberStatText{
        font-size: 250%;
    }
}