.scaleText{
    font-size: small;
    color: white;
    margin: auto;
    margin-top: -0.5rem;
    margin-right: 0%;
    width: 100%;
}

.line{
    position: absolute;
    width: 68%;
    margin-left: 15%;
    margin-top: -0.5rem;
}

.verticalLine{
    position: absolute;
    border-left: 1px solid white;
    height: 98%;
    margin-left: 10%;
    margin-top: .5%;
}