.statText{
    font-size: larger;
    color: black;
    text-align:center;
    margin:auto;
}

.statTextOver{
    font-size: larger;
    color: rgb(255, 255, 255);
    text-align:center;
    margin-top:-1em;
    text-shadow: 0.05rem 0.05rem 0.1rem #000000;
}

@media only screen and (max-width: 1240px) {

    .statText{
        font-size: small;
    }
    
    .statTextOver{
        font-size: small;
    }
}

@media only screen and (max-width: 1080px) {
    .statText{
        font-size: large;
    }
    
    .statTextOver{
        font-size: large;
    }
}

@media only screen and (max-width: 420px) {
    .statText{
        font-size: smaller;
    }
    
    .statTextOver{
        font-size: smaller;
    }
}